.table {
  --ag-row-hover-color: #fcfcfc;
  --ag-selected-row-background-color: #f5f8ff;
}

.not-lagging :global(.ag-row) {
  @apply opacity-100 transition-opacity duration-150;
}
.lagging :global(.ag-row) {
  @apply opacity-50 transition-opacity duration-150;
}

.auto-height-table {
  --ag-row-hover-color: transparent;
  --ag-borders: none;
  --ag-row-border-style: none;
}

.auto-height-table :global(.ag-row) {
  @apply z-0 rounded-lg hover:z-10;
  @apply bg-white hover:shadow-card-md;
}

.auto-height-table :global(.ag-row::before) {
  /* Remove a on-hover element that messes with the border radius/shadow */
  display: none;
}

.auto-height-table :global(.ag-row.ag-row-selected) {
  @apply bg-blue-50 hover:shadow-card-cta;
}

.overflow :global(.ag-center-cols-container),
.overflow :global(.ag-center-cols-viewport),
.overflow :global(.ag-center-cols-clipper),
.overflow :global(.ag-body-viewport),
.overflow :global(.ag-body-clipper),
.overflow :global(.ag-root),
.overflow :global(.ag-root-wrapper-body),
.overflow :global(.ag-root-wrapper) {
  overflow: visible;
}

.auto-height-table :global(.ag-row.drop-target:not(.ag-row-dragging)) {
  @apply z-20 bg-blue-50 shadow-card-cta outline -outline-offset-[0.5px] outline-blue-400;
  /* Avoid using border here as it moves contents */
}

.fixed-height-table :global(.ag-row.drop-target:not(.ag-row-dragging)) {
  @apply z-20 border border-blue-400 bg-blue-50 shadow-card-cta;
  @apply rounded-ms;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  /* Forced to use border here as outline is obscured by other rows / table grid */
}
