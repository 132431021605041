.ag-grid {
  --ag-alpine-active-color: #1919ef;

  --ag-row-hover-color: #fafafa;
  --ag-selected-row-background-color: var(--background-secondary-1);
  --ag-data-color: #444444;
  --ag-borders: none;
  --ag-borders-critical: solid 1px;
  --ag-row-border-style: solid;
  --ag-row-border-width: 1px;
  --ag-row-border-color: var(--stroke-base-2);
  --ag-border-color: #f1f1f1;

  --ag-foreground-color: #444444;
  --ag-header-background-color: transparent;
  --ag-odd-row-background-color: transparent;
  /* Not sure why but the table seems to be this + 1 px */
  --ag-font-size: 12px;
  --ag-font-family: NHaasGrotesk Text, Helvetica Neue, Open Sans, Helvetica, Arial, ui-sans-serif, system-ui,
    -apple-system, BlinkMacSystemFont, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji" html;
}

.ag-grid :global(.ag-theme-alpine) {
  --ag-alpine-active-color: #1919ef;
  --ag-input-focus-box-shadow: none;
  --ag-input-focus-border-color: #1919ef;
}

.ag-grid :global(.ag-theme-alpine .ag-menu) {
  --ag-card-shadow: 0px 5px 8px rgba(229, 229, 229, 0.25), 0px 4px 24px rgba(187, 187, 187, 0.5);
  --ag-borders: none;
}

.ag-grid :global(.ag-header) {
  font-size: 13px;
  font-weight: bold;
}

.ag-grid :global(.ag-header .ag-header-cell-resize) {
  @apply opacity-0 transition-opacity;
}
.ag-grid :global(.ag-header:hover .ag-header-cell-resize) {
  @apply opacity-100;
}

.ag-grid :global(.ag-header-cell-label) {
  @apply text-10-10 font-bold uppercase text-text-base-4;
  --ag-icon-font-color: var(--text-base-4);
}

.ag-grid :global(.ag-right-aligned-cell) {
  font-variant-numeric: tabular-nums;
}

.ag-grid :global(.ag-theme-alpine .ag-menu) {
  background-color: white;
}

.ag-grid :global(.row-edited:before),
.ag-grid :global(.row-added::before) {
  background-color: theme("colors.orange.100");
  /* Ag grid using a linear gradient of a the same color so we need to override that too */
  background-image: none;
  /* border: 8px solid var(--background-warning-1); */
}

.ag-grid :global(.row-selected.row-edited.row-hover::before),
.ag-grid :global(.row-selected.row-added.row-hover::before) {
  background-color: black;
  /* border: 8px solid var(--background-warning-1); */
}


/* 
Make width of rows expand to fill space.
Otherwise, row borders may not extend fully.
*/
.ag-grid :global(.ag-center-cols-container) {
  min-width: 100%;
}